import { Day } from "./Day";

export function Week({ start }: { start: number }) {
  const TODAY = 3;
  return (
    <tr>
      {Array(7)
        .fill(0)
        .map((_, i) => (
          <td key={i}>
            <Day day={i + start} selected={i + start === TODAY} />
          </td>
        ))}
    </tr>
  );
}
