import cn from "classnames";

export function Day({
  day,
  selected = false,
}: {
  day: number;
  selected?: boolean;
}) {
  return (
    <div
      className={cn(
        "px-2",
        "py-2",
        "cursor-pointer",
        "flex w-full",
        "justify-center",
      )}
    >
      <button
        className={cn({
          "btn btn-circle btn-sm": true,
          "btn-outline": selected,
        })}
      >
        {day}
      </button>
    </div>
  );
}
