import { type Event, Status } from "../models";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
} from "react";

const today = new Date();
const initialEvents = [
  {
    id: "1",
    routineId: "1",
    date: today,
    status: Status.NotStarted,
  },
  {
    id: "2",
    routineId: "2",
    date: today,
    status: Status.InProgress,
    current: 1,
  },
  {
    id: "3",
    routineId: "3",
    date: today,
    status: Status.Completed,
    current: 2,
  },
  {
    id: "4",
    routineId: "4",
    date: today,
    status: Status.Skipped,
  },
  {
    id: "5",
    routineId: "5",
    date: today,
    status: Status.Completed,
    current: 0,
  },
  {
    id: "6",
    routineId: "6",
    date: today,
    status: Status.NotStarted,
  },
] satisfies Event[];
const EventContext = createContext<Event[]>(initialEvents);

export function useEvents() {
  return useContext(EventContext);
}

export function EventsProvider({ children }: PropsWithChildren) {
  const [events] = useReducer(eventReducer, initialEvents);

  return (
    <EventContext.Provider value={events}>{children}</EventContext.Provider>
  );
}

function eventReducer(state: Event[]): Event[] {
  return [...state];
}
