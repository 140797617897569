import { Week } from "./Week";

export function Calendar({ weeks }: { weeks: number }) {
  return (
    <div className="flex items-center justify-between overflow-x-auto">
      <table className="w-full">
        <tr>
          <td>Sun</td>
          <td>Mon</td>
          <td>Tue</td>
          <td>Wed</td>
          <td>Thu</td>
          <td>Fri</td>
          <td>Sat</td>
        </tr>
        {Array(weeks)
          .fill(0)
          .map((_, i) => (
            <Week key={i} start={i * 7 + 1} />
          ))}
      </table>
    </div>
  );
}
