export enum Status {
  NotStarted = "NS",
  InProgress = "IP",
  Skipped = "S",
  Completed = "C",
}

export interface Progress {
  date: Date;
  status: Status;
  current?: number;
}
