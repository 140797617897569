import { type Routine } from "../models";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
} from "react";

const initialRoutines = [
  {
    id: "1",
    trigger: "저녁 만들면서",
    action: "런치 싸기",
    goal: 1,
  },
  {
    id: "2",
    trigger: "하루종일",
    action: "2리터 물 마시기",
    unit: "리터",
    emoji: "💧",
    goal: 2,
  },
  {
    id: "3",
    trigger: "아침에 일어나면",
    action: "3분동안 양치",
    unit: "mins",
    goal: 3,
  },
  {
    id: "3",
    trigger: "10시에",
    action: "일 시작하기",
    goal: 1,
  },
  {
    id: "4",
    trigger: "2시까지",
    action: "핸드폰 요금 내기",
    goal: 1,
  },
  {
    id: "5",
    trigger: "5시부터 8시 사이에",
    action: "30분 동안 운동하기",
    goal: 1,
  },
  {
    id: "6",
    trigger: "7시이후에",
    action: "군것질 안하기",
    goal: 1,
  },
] satisfies Routine[];
const RoutineContext = createContext<Routine[]>(initialRoutines);

export function useRoutines() {
  return useContext(RoutineContext);
}

export function EventsProvider({ children }: PropsWithChildren) {
  const [routines] = useReducer(routineReducer, initialRoutines);

  return (
    <RoutineContext.Provider value={routines}>
      {children}
    </RoutineContext.Provider>
  );
}

function routineReducer(state: Routine[]): Routine[] {
  return [...state];
}
