import type * as models from "../../models";

export function Progress({
  status,
  goal,
  current,
  unit,
  emoji,
}: models.Progress & models.Routine) {
  let percent = 0;
  let label = `?/${goal}`;
  if (current && goal) {
    percent = Math.min(Math.round((current / goal) * 100), 100);
    label = `${current}/${goal}`;
  }
  return (
    <div className="border border-indigo-600">
      {status} {emoji}
      <div
        className="radial-progress"
        style={{ "--value": percent, "--size": "2.5em" }}
        role="progressbar"
      >
        {label} {unit}
      </div>
    </div>
  );
}
