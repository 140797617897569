import { Nav } from "./components/Nav";
import { useView } from "./contexts/ViewContext";
import { View } from "./models";
import { Events } from "./views/Events";
import { Routines } from "./views/Routines";
import { Settings } from "./views/Settings";

const views = {
  [View.Events]: <Events />,
  [View.Routines]: <Routines />,
  [View.Settings]: <Settings />,
} satisfies Record<View, JSX.Element>;

export function App() {
  const view = useView();

  return (
    <>
      {views[view]}
      <Nav view={view} />
    </>
  );
}
