import { App } from "./App";
import { ViewProvider } from "./contexts/ViewContext";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <div className="container min-h-screen max-w-sm mx-auto border-x px-[var(--padding)]">
      <ViewProvider>
        <App />
      </ViewProvider>
    </div>
  </React.StrictMode>,
);
