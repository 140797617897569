import { View } from "../models";
import {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
} from "react";

const initialView = View.Events;
const ViewContext = createContext<View>(initialView);
const ViewDispatchContext = createContext<Dispatch<View>>(() => {});

export function useView() {
  return useContext(ViewContext);
}

export function useViewDispatch() {
  return useContext(ViewDispatchContext);
}

export function ViewProvider({ children }: PropsWithChildren) {
  const [view, dispatch] = useReducer(viewReducer, initialView);

  return (
    <ViewContext.Provider value={view}>
      <ViewDispatchContext.Provider value={dispatch}>
        {children}
      </ViewDispatchContext.Provider>
    </ViewContext.Provider>
  );
}

function viewReducer(_oldView: View, newView: View): View {
  return newView;
}
