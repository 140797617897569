import type * as models from "../../models";
import { Progress } from "./Progress";
import { That } from "./That";
import { This } from "./This";

interface EventProps {
  event: models.Event;
  routine: models.Routine;
}

export function Event({ event, routine }: EventProps) {
  return (
    <div className="flex flex-row">
      <This {...routine} />
      <That {...routine} />
      <Progress {...routine} {...event} />
    </div>
  );
}
