import type * as models from "../../models";
import { Goal } from "./Goal";
import { That } from "./That";
import { This } from "./This";

export function Routine({ ...routine }: models.Routine) {
  return (
    <div className="flex flex-row">
      <This {...routine} />
      <That {...routine} />
      <Goal {...routine} />
    </div>
  );
}
