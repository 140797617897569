import { Calendar } from "../components/calendar/Calendar";
import { Event } from "../components/wttt/Event";
import { EventsProvider, useEvents } from "../contexts/EventsContext";
import { useRoutines } from "../contexts/RoutinesContext";
import * as models from "../models";

export function Events() {
  const events = useEvents();
  const routines = useRoutines();

  function eventAndRoutine(event: models.Event, index: number) {
    const routine = routines.find((r) => r.id === event.id)!;
    return <Event key={index} event={event} routine={routine} />;
  }

  return (
    <EventsProvider>
      <Calendar weeks={1} />
      {events.map(eventAndRoutine)}
    </EventsProvider>
  );
}
